@import "advanced-content-template";

.mb-content-cookie {
    display: none;
}

.designMode .mb-content-cookie,
.show .mb-content-cookie {
    display: block;
}

.mb-content .mb-grey-backgournd {
    background-color: #ddd;
    padding: 0 23px 20px;
}

div[data-pid="page_1454948703218"] {
    .mb-content .mb-grey-backgournd {
        margin-bottom: -131px;
    }
}